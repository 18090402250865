/* eslint-disable */
import React from 'react'

const Gallery = () => (
  <section id="gallery" className="ls section_padding_top_100 section_padding_bottom_75">
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          <h5 className="section_header">Our Gallery</h5>
          <div id="gallery-owl-carousel" className="owl-carousel dots-center with_border_items" data-dots="true"
            data-items="3" data-responsive-lg="3" data-responsive-md="2" data-responsive-sm="2"
            data-responsive-xs="1">
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/01.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/01.jpg"></a>
                  </div>
                </div>
              </div>


            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/02.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/02.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/03.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/03.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/04.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/04.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/05.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/05.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/06.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/06.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/07.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/07.jpg"></a>
                  </div>
                </div>
              </div>


            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/08.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/08.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/09.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/09.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/10.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/10.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/11.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/11.jpg"></a>
                  </div>
                </div>
              </div>



            </article>
            <article className="vertical-item content-padding post format-standard with_border  text-center">

              <div className="item-media">

                <img src="images/gallery/12.jpg" alt="" />

                <div className="media-links">
                  <div className="links-wrap">
                    <a className="p-view prettyPhoto " title="" data-gal="prettyPhoto[gal]"
                      href="images/gallery/12.jpg"></a>
                  </div>
                </div>
              </div>


            </article>


          </div>
        </div>


      </div>
    </div>
  </section>
)
export default Gallery