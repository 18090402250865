import React, { Component } from 'react';

class ReviewItem extends Component {
  constructor(props) {
    super(props);
  }


  render() {
    const { review } = this.props;
    return (
      <blockquote className="with_border with_padding big-padding text-center margin_0">
        <img src={review.userPhoto} alt="" />
        {review.text}
        <hr className="divider_60_2 main_bg_color divider_25" />
        <div className="item-meta topmargin_0">
          <h5>{review.username}</h5>
          <div className="starsContainer">
            <span className={review.stars >= 1 ? 'fa fa-star checked' : 'fa fa-star'}></span>
            <span className={review.stars >= 2 ? 'fa fa-star checked' : 'fa fa-star'}></span>
            <span className={review.stars >= 3 ? 'fa fa-star checked' : 'fa fa-star'}></span>
            <span className={review.stars >= 4 ? 'fa fa-star checked' : 'fa fa-star'}></span>
            <span className={review.stars >= 5 ? 'fa fa-star checked' : 'fa fa-star'}></span>
          </div>
        </div>
      </blockquote>
    );
  }
}

export default ReviewItem;
