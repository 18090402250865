import React from 'react';

import ReviewItem from './ReviewItem';

const ReviewList = ({
  reviews,
}) => (
    <div className="owl-carousel testimonials-owl-carousel topmargin_60" 
      data-responsive-lg="3"
      data-responsive-md="2" 
      data-responsive-sm="2" 
      data-dots="true" 
      data-nav="false">

      {reviews.map(review => (

        <ReviewItem
          key={review.createdAt}
          review={review}
        />

      ))}
    </div>
  );

export default ReviewList;
