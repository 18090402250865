import React from 'react'

const Hero = () => (
  <section id="hero" className="intro_section page_mainslider ds">
    <div className="flexslider" data-nav="false">
      <ul className="slides">
        <li>
          <img src="/images/slide01.jpg" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="slide_description_wrapper">
                  <div className="slide_description">
                    <div className="intro-layer" data-animation="fadeInUp">
                      <h4>Welcome to Coronado Roofing</h4>
                      <h2>Expert Roofing</h2>
                    </div>
                    <div className="intro-layer" data-animation="fadeInUp">
                      <p>We have more than 10 years of experience providing quality solutions for businesses and
                            homeowners in the area, and the capability to efficiently</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li>
          <img src="images/slide02.jpg" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="slide_description_wrapper">
                  <div className="slide_description">
                    <div className="intro-layer" data-animation="fadeInUp">
                      <h4>Welcome to Coronado Roofing</h4>
                      <h2>Professional Workers</h2>
                    </div>
                    <div className="intro-layer" data-animation="fadeInUp">
                      <p>We have more than 10 years of experience providing quality solutions for businesses and
                            homeowners in the area, and the capability to efficiently</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>

        <li>
          <img src="images/slide03.jpg" alt="" />
          <div className="container">
            <div className="row">
              <div className="col-sm-12 text-center">
                <div className="slide_description_wrapper">
                  <div className="slide_description">
                    <div className="intro-layer" data-animation="fadeInUp">
                      <h4>Welcome to Coronado Roofing</h4>
                      <h2>Trusted Contractor</h2>
                    </div>
                    <div className="intro-layer" data-animation="fadeInUp">
                      <p>We have more than 10 years of experience providing quality solutions for businesses and
                            homeowners in the area, and the capability to efficiently</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>

  </section>
)

export default Hero