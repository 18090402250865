import React from 'react'

const Offerings = () => (
  <section id="offerings" className="cs page_services background_cover section_padding_100">
    <div className="container">
      <div className="row">
        <div className="col-md-7 col-lg-6">
          {/* <h5 className="regular text-uppercase grey">What we offer</h5> */}
          <h2 className="section_header grey">Our Services</h2>

          <div className="black">A Wide Range of Roofs We Can Install
            <ul>
              <li>Built-up (hot)</li>
              <li>Peal and stick roofing</li>
              <li>Single ply</li>
              <li>Tile (concrete and clay)</li>
              <li>Roof maintenance and recoats</li>
              <li>Repairs</li>
            </ul>
          </div>

          <div className="row columns_margin_top_20">
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-wrench size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Roof <br /> <span className="regular">Repairing</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-hummer size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Roof <br /> <span className="regular">Installation</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-tube size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Roof <br /> <span className="regular">Leak repair</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-cutters size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Emergency <br /> <span className="regular">Repair</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-helmet size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Roof <br /> <span className="regular">Management</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
            <div className="col-sm-4">
              <div className="teaser hover-bg-teaser vertical-item light_bg_color text-center">
                <div className="item-content">
                  <i className="ecoroof-roof size_normal highlight"></i>
                  <h5 className="text-uppercase grey bottommargin_0">
                    Roof <br /> <span className="regular">Coating</span>
                  </h5>
                </div>
                <div className="media-links no-color-overlay">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>

)

export default Offerings