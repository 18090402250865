import React from 'react'

const Services = () => (
  <section id="services" className="ls section_padding_100">
    <div className="container">
      <div className="row">
        <div className="col-sm-12 text-center">
          <h5 className="regular text-uppercase">Welcome!</h5>
          <h2 className="section_header">What we offer</h2>

          <p className="text-center regular lightgrey">Is rainwater seeping through the roof of your home? Give the
            professionals at Coronado Roofing LLC a call today to avoid major expenses in the future. You can get
            immediate roof repairs for all types of roofs including flat roof, shingle roof, tile roof, and built-up
                roof. We provide emergency services too! Call us today!</p>
        </div>
      </div>
      <div className="row topmargin_30 columns_margin_bottom_20">
        <div className="col-md-4 col-sm-6">
          <div
            className="vertical-item content-padding hover-content big-padding bg_teaser after_cover darkgrey_bg text-center ds">
            <img src="images/teaser01.jpg" alt="" />
            <div className="media-links"></div>
            <div className="item-content">
              <h3 className="text-uppercase">
                Commercial <br /> <span className="regular">Roofing</span>
              </h3>
              <div className="content-body">
                <p>Call us to get exceptional roof installations and repairs at affordable rates.</p>
                <p className="topmargin_30">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-6">
          <div
            className="vertical-item content-padding hover-content big-padding bg_teaser after_cover darkgrey_bg text-center ds">
            <img src="images/teaser02.jpg" alt="" />
            <div className="media-links"></div>
            <div className="item-content">
              <h3 className="text-uppercase">
                Residential <br /> <span className="regular">Roofing</span>
              </h3>
              <div className="content-body">
                <p>Create an extra living space by having a flat roof installed on your home.</p>
                <p className="topmargin_30">
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 col-sm-12">
          <div
            className="vertical-item content-padding hover-content big-padding bg_teaser after_cover darkgrey_bg text-center ds">
            <img src="images/teaser03.jpg" alt="" />
            <div className="media-links"></div>
            <div className="item-content">
              <h3 className="text-uppercase">
                Re-Roofing <br /> <span className="regular">Service</span>
              </h3>
              <div className="content-body">
                <p>Get a cost-effective shingle roof installed on your home by our experienced professionals. </p>
                <p className="topmargin_30">
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row topmargin_30">
        <div className="col-sm-12 text-center">
        </div>
      </div>
    </div>
  </section>
)

export default Services