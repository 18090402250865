import React, { Component } from 'react';
import { withFirebase } from '../Firebase'

import ReviewList from './ReviewList'
class Reviews extends Component {
  _initFirebase = false;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      reviews: [],
      limit: 5,
    };
  }

  firebaseInit = () => {
    if (this.props.firebase && !this._initFirebase) {
      this._initFirebase = true;

      this.onListenForReviews();
    }
  };

  componentDidMount() {
    this.firebaseInit();
  }

  componentDidUpdate() {
    this.firebaseInit();
  }

  onListenForReviews = () => {
    this.setState({ loading: true });

    this.props.firebase
      .reviews()
      .orderByChild('createdAt')
      .limitToLast(this.state.limit)
      .on('value', snapshot => {
        const reviewObject = snapshot.val();

        if (reviewObject) {
          const reviewList = Object.keys(reviewObject).map(key => ({
            ...reviewObject[key],
            uid: key,
          }));

          this.setState({
            reviews: reviewList,
            loading: false,
          });
          if (window && window.loadCarousels){
            var checkCarousels = setInterval(function() {
              if (window.document.querySelectorAll(".testimonials-owl-carousel blockquote").length) {
                setTimeout(()=>{
                  window.loadCarousels();
                  clearInterval(checkCarousels);
                },1000)                
              }
           }, 100);
            
          }
        } else {
          this.setState({ reviews: null, loading: false });
        }
      });


  };

  componentWillUnmount() {
    this.props.firebase.reviews().off();
  }

  render() {
    const { reviews, loading } = this.state;
    return (
      <section id="reviews" className="ds parallax page_testimonials section_padding_top_150 section_padding_bottom_130">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 text-center">
              <h5 className="regular text-uppercase">What people say</h5>
              <h2 className="section_header">Testimonials</h2>

              {loading && <div>Loading ...</div>}
              {reviews && (
                <ReviewList reviews={reviews} />
              )}

            </div>
          </div>
          <div class="row topmargin_30">
						<div class="col-sm-12 text-center">
							<a href="/home" class="theme_button color1 inverse">Add Your Review</a>
						</div>
					</div>
        </div>
      </section>
    )
  }

}


export default withFirebase(Reviews);