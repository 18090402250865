import React from 'react'

const Contact = () => (
  <section id="contact"
    className="cs grey page_contacts parallax section_padding_top_100 section_padding_bottom_75 columns_margin_bottom_30">
    <div className="container">
      <div className="row">
        <div className="col-lg-6 col-sm-4 text-center">
          <div>
            <h3 className="text-uppercase">Call us</h3>
            <p>
              <strong>Phone:</strong> (520) 273-5626<br />
            </p>
          </div>
        </div>
        <div className="col-lg-6 col-sm-4 col-sm-12 text-center">
          <div>
            <h3 className="text-uppercase">Email us</h3>
            <p>
              <a href="mailto:ecoronado66@gmail.com">ecoronado66@gmail.com</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
)

export default Contact