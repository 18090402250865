import React from 'react';
import Layout from '../components/layout';
import Hero from '../components/Hero/Hero';
import Services from '../components/Services/Services'
import Reviews from '../components/Reviews/Reviews';
import Offerings from '../components/Offerings/Offerings';
import Gallery from '../components/Gallery/Gallery';
import Contact from '../components/Contact/Contact';

export default () => (
  <Layout>
    <Hero />
    <Services />
    <Offerings />
    <Reviews />
    <Gallery />
    <Contact />
  </Layout>
);
